@import 'src/utils/utils';

.profile-form {

  .suggestions-wrapper {
    @include Shadow-1;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
    background-color: $Main-2;
    overflow: hidden;

    .suggestions-suggestion {
      @include Text-16-reg;
      padding-top: 10px;
      padding-right: 16px;
      padding-bottom: 10px;
      padding-left: 16px;
      transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        @media(min-width: $lg) {
          background-color: $Main-4;
        }
      }

      &:active {
        background-color: $Main-4;
      }

      strong {
        color: #ff0000;
      }
    }

    .suggestions-promo {
      display: none;
    }

    .suggestions-hint {
      display: none;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    border: 0;
    gap: 18px;

    @media(min-width: $md) {
      gap: 20px;
    }
  }

  &__field {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .tooltip__button {
      position: absolute;
      top: 5px;
      right: 5px;

      @media(min-width: $md) {
        top: 8px;
        right: 10px;
      }
    }
  }

  &__field-inner {
    position: relative;
    display: flex;
    flex-direction: column;

    button + input {
      padding-right: 50px;
    }

    .validator__error {
      @include Text-12-reg;
      margin: 0;
    }
  }

  &__label {
    @include Text-12-reg;
    margin: 0;
  }

  &__action {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    gap: 15px;

    @media(min-width: $md) {
      flex-direction: row-reverse;
      gap: 25px;
    }

    .button {
      flex-grow: 1;
    }
  }
}